import * as Yup from 'yup';

const READ_ONLY_FIELDS = {
  id: Yup.number().integer().required(),
  creationTS: Yup.string(),
  generationStatus: Yup.string(),
  campaignId: Yup.number().integer(),
};

export const EDITABLE_FIELDS = {
  visibility: Yup.string().required().default('').label('Widoczność'),
  lenght: Yup.number().integer().required().default(0),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const gaidSchema = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
});
