import {
  CAN_CHANGE_PROPOSAL_DEALS,
  CAN_EDIT_ADVERTISERS,
  CAN_EDIT_CAMPAIGNS,
  CAN_EDIT_GLOBAL_TARGETINGS,
  CAN_EDIT_MARGIN,
  CAN_EDIT_MODELSETS,
  CAN_EDIT_TARGETINGS,
  CAN_OVERRIDE_BUDGET_VISIBILITY,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_SET_LINEITEM_DATA_COLLECTION,
  CAN_VIEW_ADVERTISERS,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_CUSTOM_TARGETINGS,
  CAN_VIEW_DYNAMIC_PRICES,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_VIEW_FINALIZED_DEALS,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_VIEW_MODELSETS,
  CAN_VIEW_TARGETINGS,
  CAN_CHANGE_GAID,
  CAN_VIEW_GAID,
  CAN_VIEW_COST,
  CAN_VIEW_LOOKER_REPORT,
  CAN_CHANGE_LOOKER_REPORT,
  CAN_DOWNLOAD_GAID,
  CAN_VIEW_BASIC_STATISTICS,
  CAN_VIEW_ADVANCED_STATISTICS,
  CAN_VIEW_CAMPAIGN_RELATED_OBJECTS,
  CAN_DOWNLOAD_CAMPAIGN_REPORT,
} from 'consts/permissions';

export const NAME = 'user';

export const initialState = {
  id: undefined,
  username: undefined,
  buyer: undefined,
  token: undefined,
  isLoggedIn: false,
  [CAN_VIEW_CAMPAIGNS]: false,
  [CAN_EDIT_CAMPAIGNS]: false,
  [CAN_PREVIEW_CAMPAIGNS]: false,
  [CAN_VIEW_MODELSETS]: false,
  [CAN_EDIT_MODELSETS]: false,
  [CAN_VIEW_TARGETINGS]: false,
  [CAN_EDIT_TARGETINGS]: false,
  [CAN_VIEW_ADVERTISERS]: false,
  [CAN_EDIT_ADVERTISERS]: false,
  [CAN_EDIT_GLOBAL_TARGETINGS]: false,
  [CAN_VIEW_DYNAMIC_PRICES]: false,
  [CAN_VIEW_HIDDEN_FEATURES]: false,
  [CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST]: false,
  [CAN_SET_LINEITEM_DATA_COLLECTION]: false,
  [CAN_EDIT_MARGIN]: false,
  [CAN_VIEW_CUSTOM_TARGETINGS]: false,
  [CAN_VIEW_EVERY_BUYER_OBJECTS]: false,
  [CAN_VIEW_FINALIZED_DEALS]: false,
  [CAN_CHANGE_PROPOSAL_DEALS]: false,
  [CAN_OVERRIDE_BUDGET_VISIBILITY]: false,
  [CAN_VIEW_GAID]: false,
  [CAN_CHANGE_GAID]: false,
  [CAN_DOWNLOAD_GAID]: false,
  [CAN_VIEW_COST]: false,
  [CAN_VIEW_LOOKER_REPORT]: false,
  [CAN_CHANGE_LOOKER_REPORT]: false,
  [CAN_VIEW_BASIC_STATISTICS]: false,
  [CAN_VIEW_ADVANCED_STATISTICS]: false,
  [CAN_VIEW_CAMPAIGN_RELATED_OBJECTS]: false,
  [CAN_DOWNLOAD_CAMPAIGN_REPORT]: false,
};
