import {
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  Fieldset,
  Form,
  PermissionsGate,
  ResetButton,
  SubmitButton,
} from 'components';
import { FormProps } from 'components/Form';
import { FormSkeleton } from 'components/Skeletons';
import {
  CAN_EDIT_CAMPAIGNS,
  CAN_SET_LINEITEM_DATA_COLLECTION,
  CAN_VIEW_CUSTOM_TARGETINGS,
  CAN_VIEW_FINALIZED_DEALS,
  CAN_VIEW_MODELSETS,
} from 'consts/permissions';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { useAppSelector } from 'hooks';
import { FormLayout, FormLayoutProps } from 'layouts';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { selectCurrentCampaign } from 'store/campaigns';
import * as Field from '../formFields';
import { lineitemShowPage } from '../Lineitem.Links';
import { validationSchema } from '../Lineitem.schema';

const FormSectionGeneral = ({
  videoElement,
}: {
  videoElement?: ReactNode;
}): JSX.Element => {
  const campaign = useAppSelector(selectCurrentCampaign);

  return (
    <Grid gap={2} templateColumns='repeat(2,1fr)'>
      <Fieldset gridColumn='span 2'>
        <Field.LineitemGeneralName gridColumn='span 12' />
        {campaign?.budgetPerLineitem && isBudgetVisible() && (
          <Field.LineitemTotalBudget gridColumn='span 6' />
        )}
      </Fieldset>
      {videoElement}
      <Fieldset heading='Event Plan' gridColumn='span 2' columns={4}>
        <Field.LineitemGeneralEventPlanEventCount gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanEventType gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanEventPlanType gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanPaceType gridColumn='span 1' />
      </Fieldset>
      <Fieldset gridColumn='span 2'>
        <Field.LineitemOptimizationsId gridColumn='span 6' />
        <PermissionsGate requiredPermissions={[CAN_VIEW_MODELSETS]}>
          <Field.LineitemModelsetId gridColumn='span 6' />
        </PermissionsGate>
      </Fieldset>
      <Fieldset heading='Minimal CTR' gridColumn='span 1' columns={2}>
        <Field.LineitemGeneralMinimalCtrValue gridColumn='span 1' />
        <Field.LineitemGeneralMinimalCtrIsActive gridColumn='span 1' />
      </Fieldset>
      <Fieldset heading='Frequency Capping' columns={3}>
        <Field.LineitemGeneralFrequencyCappingCount gridColumn='span 1' />
        <Field.LineitemGeneralFrequencyCappingPeriod gridColumn='span 1' />
        <Field.LineitemGeneralFrequencyCappingEnabled gridColumn='span 1' />
      </Fieldset>
      <PermissionsGate requiredPermissions={[CAN_VIEW_FINALIZED_DEALS]}>
        <Fieldset heading='Private Marketplace' gridColumn='span 2' columns={4}>
          <Field.LineitemGeneralPrivateMarketplace gridColumn='span 4' />
        </Fieldset>
      </PermissionsGate>
    </Grid>
  );
};

const FormSectionGeneralVideo = (): JSX.Element => (
  <FormSectionGeneral videoElement={<FormSectionVideo />} />
);

const FormSectionTiming = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={12} heading='Duration'>
      <Field.LineitemGeneralTimingStartDate gridColumn='span 4' />
      <Field.LineitemGeneralTimingEndDate gridColumn='span 4' />
    </Fieldset>
    <Fieldset columns={12} heading='Limit to'>
      <Field.LineitemGeneralTimingDailyTiming gridColumn='span 8' />
      <Field.LineitemGeneralTimingHourlyTiming gridColumn='span 3' />
    </Fieldset>
  </Grid>
);

const FormSectionTargetingGeneral = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={1} heading='Environment'>
      <Field.LineitemTargetingTrafficSource />
      <Field.LineitemTargetingOperatingSystem />
      <Field.LineitemTargetingExchange />
    </Fieldset>
    <Fieldset columns={4} heading='Campaign Retargeting'>
      <Field.LineitemTargetingCampaignRetargetingCampaignIds gridColumn='span 4' />
      <Field.LineitemTargetingCampaignRetargetingTargetingType gridColumn='span 3' />
      <PermissionsGate requiredPermissions={[CAN_SET_LINEITEM_DATA_COLLECTION]}>
        <Field.LineitemTargetingCollectingDataActive gridColumn='span 1' />
      </PermissionsGate>
    </Fieldset>

    <Fieldset columns={1} heading='User Categories'>
      <Field.LineitemTargetingUserCategoryTargetingUserCategories />
      <Field.LineitemTargetingUserCategoryTargetingExclude />
    </Fieldset>
    <PermissionsGate requiredPermissions={[CAN_VIEW_CUSTOM_TARGETINGS]}>
      <Fieldset columns={1} heading='Targeting Lists'>
        <Field.LineitemTargetingsInclude />
        <Field.LineitemTargetingsExclude />
      </Fieldset>
    </PermissionsGate>
  </Grid>
);

const FormSectionTargetingGeographical = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={1} heading='Countries'>
      <Field.LineitemTargetingCountry />
    </Fieldset>
    <Fieldset columns={1} heading='Provinces'>
      <Field.LineitemTargetingRegionList />
      <Field.LineitemTargetingRegionExclude />
    </Fieldset>
    <Fieldset columns={1} heading='Cities'>
      <Field.LineitemTargetingCityList />
      <Field.LineitemTargetingCityExclude />
    </Fieldset>
    <Fieldset columns={6} heading='Geotargeting Lists'>
      <Field.LineitemTargetingGeoTargetingListTargeting gridColumn='span 4' />
      <Field.LineitemTargetingGeoTargetingListPrecision />
      <Field.LineitemTargetingGeoTargetingListEnabled />
    </Fieldset>
  </Grid>
);

const FormSectionTargetingSoftware = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={1} heading='Apps'>
      <Field.LineitemTargetingAppPlacementTargetingPlacementList />
      <Field.LineitemTargetingAppPlacementTargetingTargetingFile />
      <Field.LineitemTargetingAppPlacementTargetingIncludePlacements />
    </Fieldset>
    <Fieldset columns={1} heading='Websites'>
      <Field.LineitemTargetingSitePlacementTargetingPlacementList />
      <Field.LineitemTargetingSitePlacementTargetingTargetingFile />
      <Field.LineitemTargetingSitePlacementTargetingIncludePlacements />
    </Fieldset>
    <Fieldset columns={1} heading='Placement categories'>
      <Field.LineitemTargetingPlacementCategoryTargetingPlacementCategories />
      <Field.LineitemTargetingPlacementCategoryTargetingExclude />
    </Fieldset>
  </Grid>
);

const FormSectionTargetingDemographic = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={1}>
      <Field.LineitemTargetingDemographicsAge />
      <Field.LineitemTargetingDemographicsGender />
    </Fieldset>
  </Grid>
);

const FormSectionTargetingDevice = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={1} heading='Device model'>
      <Field.TargetingDevices />
    </Fieldset>
    <Fieldset columns={1} heading='Device language'>
      <Field.LineitemTargetingLanguage />
    </Fieldset>
  </Grid>
);

const FormSectionCreatives = (): JSX.Element => (
  <Fieldset columns={1} variant='ghost'>
    <Field.CreativesList />
  </Fieldset>
);

const FormSectionVideo = (): JSX.Element => (
  <>
    <Fieldset heading='Video' gridColumn='span 2'>
      <Field.LineitemVideoAllowSkippable gridColumn='span 2' />
      <Field.LineitemVideoPlacementTypes gridColumn='span 10' />
      <Field.LineitemVideoPlaybackMethod gridColumn='span 6' />
      <Field.LineitemVideoStartDelay gridColumn='span 6' />
    </Fieldset>
    <Fieldset heading='Minimal VCR' gridColumn='span 2'>
      <Field.LineitemGeneralMinimalVcrValue gridColumn='span 9' />
      <Field.LineitemGeneralMinimalVcrIsActive gridColumn='span 3' />
    </Fieldset>
  </>
);

export const LineitemFormFieldsAsTabs = ({
  generalSection,
}: {
  generalSection: ReactNode;
}): JSX.Element => (
  <Tabs isLazy>
    <TabList>
      <Tab>General settings</Tab>
      <Tab>Timing settings</Tab>
      <Tab>Targeting options</Tab>
      <Tab>Creatives</Tab>
    </TabList>

    <TabPanels>
      <TabPanel>{generalSection}</TabPanel>
      <TabPanel>
        <FormSectionTiming />
      </TabPanel>
      <TabPanel>
        <Tabs isLazy>
          <TabList>
            <Tab>General</Tab>
            <Tab>Location</Tab>
            <Tab>Inventory</Tab>
            <Tab>Demographic</Tab>
            <Tab>Device</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FormSectionTargetingGeneral />
            </TabPanel>
            <TabPanel>
              <FormSectionTargetingGeographical />
            </TabPanel>
            <TabPanel>
              <FormSectionTargetingSoftware />
            </TabPanel>
            <TabPanel>
              <FormSectionTargetingDemographic />
            </TabPanel>
            <TabPanel>
              <FormSectionTargetingDevice />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </TabPanel>
      <TabPanel>
        <FormSectionCreatives />
      </TabPanel>
    </TabPanels>
  </Tabs>
);

export const LineitemFormFields = (): JSX.Element => (
  <LineitemFormFieldsAsTabs generalSection={<FormSectionGeneral />} />
);

export const VideoLineitemFormFields = (): JSX.Element => (
  <LineitemFormFieldsAsTabs generalSection={<FormSectionGeneralVideo />} />
);

export const LineitemFormActions = (): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <FormLayout.Actions>
      <SubmitButton />
      <ResetButton />
    </FormLayout.Actions>
  </PermissionsGate>
);

interface LineitemFormProps extends FormProps, FormLayoutProps {
  redirectOnSuccess?: boolean;
}
export const LineitemForm = ({
  defaultValues,
  template,
  redirectOnSuccess,
  children,
  onSubmit,
  ...props
}: LineitemFormProps): JSX.Element => {
  const history = useHistory();

  function handleSubmit(lineitem: any): void {
    if (lineitem.id && redirectOnSuccess) {
      history.push(lineitemShowPage(lineitem.id));
    }
    if (typeof onSubmit === 'function') onSubmit(lineitem);
  }

  if (!defaultValues) return <FormSkeleton />;

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={validationSchema(isBudgetVisible())}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      {...props}
    >
      <FormLayout template={template}>{children}</FormLayout>
    </Form>
  );
};
