import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './gaids.consts';
import { GaidsParams } from './gaids.types';
import { selectGaid } from './gaids.selectors';

export const fetchAllGaids = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async ({ campaignId }: { campaignId: number }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/gaids`,
      method: 'GET',
      headers: {},
    });
    return response.data.map((gaid: GaidsParams) => ({
      ...gaid,
      campaignId,
    }));
  }),
);

export const generate = createAsyncThunk(
  REDUCER_KEY + '/generate',
  withErrorHandling(async (data: GaidsParams) => {
    const { campaignId } = data;
    if (!campaignId) throw new Error('no campaignId');
    const response = await adserver({
      url: `/campaign/${campaignId}/gaids/generate`,
      method: 'POST',
      data: data,
      headers: {},
    });
    return { ...response.data, campaignId: campaignId };
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: GaidsParams, thunkApi: any) => {
    const state = thunkApi.getState();
    const { campaignId, id } = data;
    if (!campaignId) throw new Error('no campaignId');
    const gaid = selectGaid(state, id);
    const response = await adserver({
      url: `/campaign/${campaignId}/gaids/${id}`,
      method: 'PATCH',
      data: data,
      headers: {},
    });
    return { ...gaid, ...response.data, campaignId: campaignId };
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(
    async ({ campaignId, gaidId }: { campaignId: number; gaidId: number }) => {
      const response = await adserver({
        url: `/campaign/${campaignId}/gaids/${gaidId}`,
        method: 'DELETE',
        headers: {},
      });
      return response.data;
    },
  ),
);

export const preview = createAsyncThunk(
  REDUCER_KEY + '/preview',
  withErrorHandling(
    async (
      {
        campaignId,
        gaidId,
        limit,
      }: { campaignId: number; gaidId: number; limit: number },
      thunkApi: any,
    ) => {
      const state = thunkApi.getState();
      const gaid = selectGaid(state, gaidId);
      const response = await adserver({
        url: `/campaign/${campaignId}/gaids/${gaidId}/preview?len=${limit}`,
        method: 'GET',
        headers: {},
      });
      return { ...gaid, list: [...response.data] };
    },
  ),
);

export const removeAllAdvertisers = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});

export const getGaidListFile = createAsyncThunk(
  REDUCER_KEY + '/getGaidListFile',
  withErrorHandling(
    async ({ campaignId, gaidId }: { campaignId: number; gaidId: number }) => {
      const { data } = await adserver({
        url: `/campaign/${campaignId}/gaids/${gaidId}/download`,
        method: 'GET',
        responseType: 'blob',
        headers: {},
      });
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `gaidList_${campaignId}.csv`);
      document.body.appendChild(link);
      link.click();
    },
  ),
);
