import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './gaids.adapter';
import { REDUCER_KEY } from './gaids.consts';
import { GaidsParams, GaidListItem } from './gaids.types';
import {
  selectCurrentCampaign,
  selectCurrentCampaignId,
} from 'store/campaigns';
import { gaidSchema } from 'components/Gaids';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): GaidsParams | null =>
  selectors.selectById(state, id) || null;

export const selectCurrentAdvertiserId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllGaids = createSelector(
  state => selectors.selectAll(state),
  advertisers => advertisers || [],
);

export const selectCurrentGaid = createSelector(
  selectCurrentAdvertiserId,
  selectAllGaids,
  (currentId: number, gaids: GaidsParams[]) =>
    gaids.find(({ id }: { id: number }) => id === currentId),
);

export function selectGaid(
  state: RootState,
  gaidId: number,
): GaidsParams | undefined {
  return selectors.selectById(state, gaidId) || undefined;
}

export const selectFormDataForNewGaidOfCurrentCampaign = createSelector(
  selectCurrentCampaign,
  campaign => {
    if (!campaign) return undefined;
    const gaid = gaidSchema.getDefault();
    gaid.campaignId = campaign.id;

    return gaid;
  },
);

export const selectGaidsAsOptions = createSelector(
  selectAll,
  gaids =>
    gaids
      .filter(
        gaid => gaid.visibility === 'available' || gaid.visibility === 'locked',
      )
      .map(gaid => ({
        id: gaid.id,
        length: gaid.length,
        visibility: gaid.visibility,
      })) || [],
);

export const selectGaidsOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAll,
  (currentCampaignId, allGaids) =>
    allGaids.filter(gaid => gaid.campaignId === currentCampaignId) || [],
);

export const selectNumberOfGaidsOfCurrentCampaign = createSelector(
  selectGaidsOfCurrentCampaign,
  gaids => gaids.length,
);

export function selectGaidList(
  state: RootState,
  gaidId: number,
): GaidListItem[] {
  const gaid = selectGaid(state, gaidId);
  return gaid ? gaid.list : [];
}
