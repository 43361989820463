import React from 'react';
import { ControlledInputText } from 'components';

export const CampaignLookerStudioReport = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputText
    name='lookerStudioReport'
    label='Looker Studio Report'
    {...props}
  />
);
