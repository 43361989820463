import store from 'store';
import { ALL_PERMISSIONS } from 'consts/permissions';

export function isBudgetVisible(): boolean {
  const user = store.getState().user;
  return user?.buyer?.budget_visibility || user?.override_budget_visibility;
}

export function hasPermission(permissions: string[]): boolean {
  const user = store.getState().user;
  const userPermissions =
    ALL_PERMISSIONS.filter(permission => user[permission] === true) || [];
  return permissions.every(key => {
    if (!userPermissions.includes(key)) {
      return false;
    }
    return true;
  });
}
