import React from 'react';
import { Grid } from '@chakra-ui/react';
import { CAN_EDIT_ADVERTISERS } from 'consts/permissions';
import { FORM_VALIDATION_SCHEMA } from './Gaid.schema';
import {
  generate,
  update,
  selectGaid,
  selectFormDataForNewGaidOfCurrentCampaign,
} from 'store/gaids';
import { useAppSelector } from 'hooks';
import { Form, SubmitButton, ResetButton, PermissionsGate } from 'components';
import { FormProps } from 'components/Form';
import { FormSkeleton } from 'components/Skeletons';
import { FormLayout, FormLayoutProps, Fieldset } from 'layouts';
import { GaidsLength, GaidVisibility } from './formFields';
import { GaidId } from './Gaid.types';
import { Title } from 'components/Meta';

interface GaidFormProps extends FormProps, FormLayoutProps {
  redirectOnSuccess?: boolean;
  editMode?: boolean;
}

const GaidForm = ({
  heading,
  defaultValues,
  onSubmit,
  template,
  editMode = false,
  ...props
}: GaidFormProps): JSX.Element => {
  function handleSubmit(gaid: any): void {
    if (typeof onSubmit === 'function') onSubmit(gaid);
  }

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={FORM_VALIDATION_SCHEMA}
      permissionsToEdit={[CAN_EDIT_ADVERTISERS]}
      {...props}
    >
      <FormLayout template={template}>
        <FormLayout.Heading>{heading}</FormLayout.Heading>
        <PermissionsGate requiredPermissions={[CAN_EDIT_ADVERTISERS]}>
          <FormLayout.Actions>
            <SubmitButton />
            <ResetButton />
          </FormLayout.Actions>
        </PermissionsGate>
        <FormLayout.Body>
          <Grid as={Fieldset} gap={6} templateColumns='repeat(12,1fr)'>
            <GaidsLength
              gridColumn={{ base: 'span 8', md: 'span 8' }}
              isDisabled={editMode}
            />
            <GaidVisibility gridColumn={{ base: 'span 4', md: 'span 4' }} />
          </Grid>
        </FormLayout.Body>
      </FormLayout>
    </Form>
  );
};

export const CreateGaidForCurrentCampaignForm = ({
  ...props
}: FormLayoutProps): JSX.Element => {
  const defaultVals = useAppSelector(selectFormDataForNewGaidOfCurrentCampaign);

  return (
    <>
      <Title name={'Create GAID List'} />
      <GaidForm
        heading={'Create GAID List'}
        messageOnSuccess='GAID list was successfully created'
        dispatchMethod={generate}
        defaultValues={defaultVals}
        {...props}
      />
    </>
  );
};

export const EditGaidForm = ({
  gaidId,
  ...props
}: FormLayoutProps & GaidId): JSX.Element => {
  const gaidValues = useAppSelector(state => selectGaid(state, gaidId));

  if (!gaidValues) return <FormSkeleton />;

  return (
    <>
      <Title name={'Edit GAID List'} />
      <GaidForm
        heading='Edit GAID List'
        messageOnSuccess='All changes was successfully saved'
        dispatchMethod={update}
        defaultValues={gaidValues}
        editMode={true}
        {...props}
      />
    </>
  );
};
