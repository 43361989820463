export const NAME = 'statistics';

export const ALL_ENTITIES = 'all';
export const ENTITY_CAMPAIGN = 'campaign';
export const ENTITY_CREATIVE = 'creative';
export const ENTITY_LINEITEM = 'lineitem';
export const ENTITY_SSP = 'ssp';
export const ENTITY_PACKAGENAME = 'app';
export const ENTITY_APPNAME = 'appname';
export const ENTITY_WEBSITE = 'site';
export const ENTITY_WEBSITENAME = 'sitename';
export const ENTITY_OS = 'os';
export const ENTITY_CITY = 'city';
export const ENTITY_COORDINATE = 'lat,long';
export const ENTITY_LAT = 'lat';
export const ENTITY_LONG = 'long';
export const ENTITY_EXCHANGE = 'exchange';
export const ENTITY_BIDFLOOR = 'bidfloor';
export const ENTITY_DEAL = 'privateDeal';

export const TIMEUNIT_TOTAL = 'total';
export const TIMEUNIT_MONTH = 'month';
export const TIMEUNIT_WEEK = 'week';
export const TIMEUNIT_DAY = 'day';
export const TIMEUNIT_HOUR = 'hour';

export const STAT_IMPRESSIONS = 'impressions';
export const STAT_UNIQUEUSERS = 'uniqueusers';
export const STAT_CLICKS = 'clicks';
export const STAT_FIRSTCLICKS = 'firstclicks';
export const STAT_CPM = 'cpm';
export const STAT_DCPM = 'dcpm';
export const STAT_MCPM = 'mcpm';
export const STAT_CTR = 'ctr';
export const STAT_CPC = 'cpc';
export const STAT_DCPC = 'dcpc';
export const STAT_MCPC = 'mcpc';
export const STAT_CPV = 'cpv';
export const STAT_DCPV = 'dcpv';
export const STAT_MCPV = 'mcpv';
export const STAT_BUDGETSPEND = 'budgetspend';
export const STAT_VIEWS = 'views';
export const STAT_VIEWSRATE = 'viewsrate';
export const STAT_TRAFFICAFFORDABLE = 'affordableTraffic';
export const STAT_TRAFFICNOTAFFORDABLE = 'notAffordableTraffic';
export const STAT_MEDIANFLOORPRICE = 'medianPriceFloor';
export const STAT_POTENTIAL = 'potential';
export const STAT_ACTIONS = 'actions';
export const STAT_POSTCLICKSACTIONS = 'postclickactions';
export const STAT_POSTVIEWACTIONS = 'postviewactions';
export const STAT_FREQ = 'freq';

export const STAT_VIDEO_LOADED = 'videoloaded';
export const STAT_VIDEO_STARTED = 'videostart';
export const STAT_VIDEO_FIRSTQUARTILE = 'videofirstquartile';
export const STAT_VIDEO_MIDPOINT = 'videomidpoint';
export const STAT_VIDEO_THIRDQUARTILE = 'videothirdquartile';
export const STAT_VIDEO_COMPLETE = 'videocomplete';
export const STAT_VIDEO_COPLITONRATE = 'vcr';

export const STATISTIC_KEYS = [
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_CPM,
  STAT_DCPM,
  STAT_MCPM,
  STAT_CTR,
  STAT_CPC,
  STAT_DCPC,
  STAT_MCPC,
  STAT_CPV,
  STAT_DCPV,
  STAT_MCPV,
  STAT_BUDGETSPEND,
  STAT_VIEWS,
  STAT_VIEWSRATE,
  STAT_TRAFFICAFFORDABLE,
  STAT_TRAFFICNOTAFFORDABLE,
  STAT_MEDIANFLOORPRICE,
  STAT_POTENTIAL,
  STAT_ACTIONS,
  STAT_POSTCLICKSACTIONS,
  STAT_POSTVIEWACTIONS,
  STAT_FREQ,
  STAT_VIDEO_LOADED,
  STAT_VIDEO_STARTED,
  STAT_VIDEO_FIRSTQUARTILE,
  STAT_VIDEO_MIDPOINT,
  STAT_VIDEO_THIRDQUARTILE,
  STAT_VIDEO_COMPLETE,
  STAT_VIDEO_COPLITONRATE,
];

/**
 * klucze statystyk dostępne w zapytaniach o statystyki
 */
export const API_INDICATORS = [
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_CTR,
  STAT_VIEWS,
  STAT_VIEWSRATE,
  STAT_ACTIONS,
  STAT_POSTCLICKSACTIONS,
  STAT_POSTVIEWACTIONS,
  STAT_CPV,
  STAT_FREQ,
  STAT_VIDEO_LOADED,
  STAT_VIDEO_STARTED,
  STAT_VIDEO_FIRSTQUARTILE,
  STAT_VIDEO_MIDPOINT,
  STAT_VIDEO_THIRDQUARTILE,
  STAT_VIDEO_COMPLETE,
  STAT_VIDEO_COPLITONRATE,
];

export const API_COORDINATE_INDICATORS = [
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_CTR,
  STAT_VIEWS,
  STAT_VIEWSRATE,
];

export const API_BUDGET_INDICATORS = [STAT_CPM, STAT_CPC, STAT_BUDGETSPEND];

export const API_BUDGET_COORDINATE_INDICATORS = [
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_CTR,
  ...API_BUDGET_INDICATORS,
  STAT_VIEWS,
  STAT_VIEWSRATE,
];

export const STAT_KEYS = [
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_CPM,
  STAT_DCPM,
  STAT_MCPM,
  STAT_CTR,
  STAT_CPC,
  STAT_DCPC,
  STAT_MCPC,
  STAT_CPV,
  STAT_DCPV,
  STAT_BUDGETSPEND,
  STAT_VIEWS,
  STAT_VIEWSRATE,
  STAT_TRAFFICAFFORDABLE,
  STAT_TRAFFICNOTAFFORDABLE,
  STAT_MEDIANFLOORPRICE,
  STAT_POTENTIAL,
  STAT_ACTIONS,
  STAT_POSTCLICKSACTIONS,
  STAT_POSTVIEWACTIONS,
  STAT_FREQ,
] as const;

export type StatisticsKeysUnion = typeof STAT_KEYS[number];

/** @type {*} klucze dla wartości wymagających skonwertowania do int */
export const NORMALIZE_TO_INT = [
  ENTITY_CAMPAIGN,
  ENTITY_CREATIVE,
  ENTITY_LINEITEM,
  ENTITY_DEAL,
  STAT_IMPRESSIONS,
  STAT_UNIQUEUSERS,
  STAT_CLICKS,
  STAT_FIRSTCLICKS,
  STAT_VIEWS,
  STAT_ACTIONS,
  STAT_POSTCLICKSACTIONS,
  STAT_POSTVIEWACTIONS,
  STAT_FREQ,
];

/** @type {*} klucze dla wartości wymagających zaokrąglenia do dwóch miejsc po przecinku */
export const NORMALIZE_ROUND = [];

export const NAMES = {
  [STAT_IMPRESSIONS]: 'Impressions',
  [STAT_UNIQUEUSERS]: 'Unique users',
  [STAT_CLICKS]: 'All clicks',
  [STAT_FIRSTCLICKS]: 'First clicks',
  [STAT_CTR]: 'CTR',
  [STAT_CPM]: 'eCPM',
  [STAT_DCPM]: 'Dynamic CPM',
  [STAT_MCPM]: 'Max CPM',
  [STAT_CPC]: 'eCPC',
  [STAT_DCPC]: 'Dynamic CPC',
  [STAT_MCPC]: 'Max CPC',
  [STAT_CPV]: 'eCPV',
  [STAT_DCPV]: 'Dynamic CPV',
  [STAT_MCPV]: 'Max CPV',
  [STAT_BUDGETSPEND]: 'Budget spend',
  [STAT_VIEWS]: 'Viewable impressions',
  [STAT_VIEWSRATE]: 'Viewability rate',
  [STAT_TRAFFICAFFORDABLE]: 'Affordable traffic',
  [STAT_TRAFFICNOTAFFORDABLE]: 'Not affordable traffic',
  [STAT_MEDIANFLOORPRICE]: 'Median floor price',
  [STAT_POTENTIAL]: 'Potential',
  [STAT_ACTIONS]: 'Actions',
  [STAT_POSTCLICKSACTIONS]: 'Post Click Actions',
  [STAT_POSTVIEWACTIONS]: 'Post View Actions',
  [STAT_FREQ]: 'Frequency',
  [STAT_VIDEO_LOADED]: 'Video loaded',
  [STAT_VIDEO_STARTED]: 'Video started',
  [STAT_VIDEO_FIRSTQUARTILE]: 'Video first quartile',
  [STAT_VIDEO_MIDPOINT]: 'Video midpoint',
  [STAT_VIDEO_THIRDQUARTILE]: 'Video third quartile',
  [STAT_VIDEO_COMPLETE]: 'Video complete',
  [STAT_VIDEO_COPLITONRATE]: 'VCR',
};

export const SHORT_NAMES = {
  [STAT_IMPRESSIONS]: 'Imp.',
  [STAT_UNIQUEUSERS]: 'Uu',
  [STAT_CLICKS]: 'All clicks',
  [STAT_FIRSTCLICKS]: 'F. clicks',
  [STAT_CTR]: 'CTR',
  [STAT_CPM]: 'eCPM',
  [STAT_DCPM]: 'd.CPM',
  [STAT_MCPM]: 'm.CPM',
  [STAT_CPC]: 'eCPC',
  [STAT_DCPC]: 'd.CPC',
  [STAT_MCPC]: 'm.CPC',
  [STAT_CPV]: 'eCPV',
  [STAT_DCPV]: 'd.CPV',
  [STAT_MCPV]: 'm.CPV',
  [STAT_BUDGETSPEND]: '$',
  [STAT_VIEWS]: 'V.Imp',
  [STAT_VIEWSRATE]: 'V.Rate',
  [STAT_ACTIONS]: 'Act',
  [STAT_POSTCLICKSACTIONS]: 'PC.Act',
  [STAT_POSTVIEWACTIONS]: 'PV.Act',
  [STAT_FREQ]: 'Freq',
  [STAT_VIDEO_LOADED]: 'Vid.Load',
  [STAT_VIDEO_STARTED]: 'Vid.Start',
  [STAT_VIDEO_FIRSTQUARTILE]: 'Vid.1/4',
  [STAT_VIDEO_MIDPOINT]: 'Vid.1/2',
  [STAT_VIDEO_THIRDQUARTILE]: 'Vid.3/4',
  [STAT_VIDEO_COMPLETE]: 'Vid.Comp',
  [STAT_VIDEO_COPLITONRATE]: 'VCR',
};

export const DEFINITIONS = {
  [STAT_TRAFFICAFFORDABLE]:
    'Percent of available lineitem traffic for which your price is above floor price set by publisher.',
  [STAT_MEDIANFLOORPRICE]:
    'Price for which 50% of available lineitem traffic will be affordable.',
  [STAT_POTENTIAL]:
    'Impressions available with lineitems configuration divided by All impressions available',
};
