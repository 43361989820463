import React from 'react';
import { ControlledInputNumber } from 'components';

const GaidsLength = ({ ...props }: { [key: string]: any }): JSX.Element => {
  return (
    <ControlledInputNumber
      name='length'
      label='Number of GAIDs'
      min={0}
      precision={0}
      step={1}
      {...props}
    />
  );
};

GaidsLength.displayName = 'Gaids.length';

export { GaidsLength };
