import { isBudgetVisible, hasPermission } from 'helpers/flagHelpers';
import adserver from 'services/adserver';
import {
  API_BUDGET_INDICATORS,
  NORMALIZE_ROUND,
  NORMALIZE_TO_INT,
  STAT_ACTIONS,
  STAT_CLICKS,
  STAT_CTR,
  STAT_FIRSTCLICKS,
  STAT_IMPRESSIONS,
  STAT_POSTCLICKSACTIONS,
  STAT_POSTVIEWACTIONS,
  STAT_UNIQUEUSERS,
  STAT_VIDEO_COMPLETE,
  STAT_VIDEO_COPLITONRATE,
  STAT_VIDEO_FIRSTQUARTILE,
  STAT_VIDEO_MIDPOINT,
  STAT_VIDEO_STARTED,
  STAT_VIDEO_THIRDQUARTILE,
  STAT_CPV,
  STAT_VIEWS,
  STAT_VIEWSRATE,
  TIMEUNIT_TOTAL,
  STAT_FREQ,
} from './statistics.consts';
import {
  CAN_VIEW_COST,
  CAN_VIEW_ADVANCED_STATISTICS,
  CAN_VIEW_BASIC_STATISTICS,
} from 'consts/permissions';

/**
 * wspólny handler dla akcji pobierających statystyki
 *
 * @param {*} query
 * @param {*} thunkApi
 * @return {*} statistics
 */
export const fetchHandler = async query => {
  const response = await adserver({
    url: '/statistics',
    params: generateFetchParams(query),
  });

  const { result, extra } = response.data;

  if (Array.isArray(result)) {
    return result.map(statistic =>
      normalizeApiResponse(statistic, query, extra),
    );
  }

  return [];
};

/**
 * generuje id wykorzystywane w adapterze;
 * dla każdego klucza z query.entities generuje key:value z odpowiedzi (np. klucz campaign: generuje campaign:ID),
 * jeśli timeunit istnieje (nie jest total) to dodaje również timeunit:VALUE
 *
 * @param {*} queryParams
 * @param {*} obj
 * @return {*} id
 */
export function generateEntityId(query, data) {
  return [
    ...Object.keys(query.entities).filter(item => item !== 'appname'),
    query.timeunit,
  ]
    .filter(e => e)
    .map(key => `${key}:${data[key]}`)
    .join('::');
}
/**
 * normalizuje dane w obiekcie ze statystyką (pojedynczy rekord)
 *
 * @param {*} statistic
 * @return {*} statistic
 */
export function normalizeApiResponse(statistic, query, extra) {
  const dataKeys = Object.keys(statistic);
  const { timeunit = TIMEUNIT_TOTAL, entityType } = query;
  const canBeExcluded = extra?.excludeColumnActive === true;

  // wartości zaokrąglane: api zwraca 10 miejsc po przecinku
  const valuesRounded = NORMALIZE_ROUND.filter(key =>
    dataKeys.includes(key),
  ).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: Math.round(statistic[key] * 100) / 100,
    }),
    {},
  );

  // wartości konwertowane do int: api zwraca je jako stringi (np. id zasobów)
  const valuesConvertedToInt = NORMALIZE_TO_INT.filter(key =>
    dataKeys.includes(key),
  ).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: Number.parseInt(statistic[key]) || 0,
    }),
    {},
  );

  return {
    ...statistic,
    ...valuesConvertedToInt,
    ...valuesRounded,
    timeunit,
    entityType,
    canBeExcluded,
    id: generateEntityId(query, statistic),
  };
}

/**
 * normalizuje parametry wysyłane do api
 * @param {*} query
 * @return {*} { group, unit, indicators, start, end }
 */
export function generateFetchParams(query) {
  const timeunit = query.timeunit || null;
  const entities = Object.keys(query.entities).map(key => {
    const value = query.entities[key];
    switch (typeof value) {
      case 'number':
        return `${key}:${value}`;
      case 'object':
        return `${key}:${value.join(':')}`;
      default:
        return `${key}`;
    }
  });

  const BASIC_INDICATORS = [
    STAT_IMPRESSIONS,
    STAT_UNIQUEUSERS,
    STAT_CLICKS,
    STAT_CTR,
    STAT_FREQ,
  ];

  const ADVANCED_INDICATORS = [
    STAT_FIRSTCLICKS,
    STAT_VIEWS,
    STAT_VIEWSRATE,
    STAT_ACTIONS,
    STAT_POSTCLICKSACTIONS,
    STAT_POSTVIEWACTIONS,
    STAT_VIDEO_STARTED,
    STAT_VIDEO_FIRSTQUARTILE,
    STAT_VIDEO_MIDPOINT,
    STAT_VIDEO_THIRDQUARTILE,
    STAT_VIDEO_COMPLETE,
    STAT_VIDEO_COPLITONRATE,
    STAT_CPV,
  ];

  const INDIOCATORS = [
    ...(hasPermission([CAN_VIEW_BASIC_STATISTICS]) ? BASIC_INDICATORS : []),
    ...(isBudgetVisible() || hasPermission([CAN_VIEW_COST])
      ? API_BUDGET_INDICATORS
      : []),
    ...(hasPermission([CAN_VIEW_ADVANCED_STATISTICS])
      ? ADVANCED_INDICATORS
      : []),
  ];

  const indicators = (query?.indicators || INDIOCATORS).join(',');

  const start =
    'start' in query
      ? query.start.toISOString().substring(0, 10)
      : '2021-04-01';
  const end = ('end' in query ? query.end : Date.now())
    .toISOString()
    .substring(0, 10);

  return {
    group: [timeunit, ...entities].filter(e => e).join(','),
    indicators,
    start,
    end,
    ...query.params,
  };
}
