import React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';

export const GaidVisibility = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const gaidListVisibilities = useAppSelector(
    state => state.consts.gaidListVisibilities,
  );

  const options = React.useMemo(() => {
    return gaidListVisibilities.map(
      ({ id, name }: { id: string; name: string }) => ({
        id,
        name,
      }),
    );
  }, [gaidListVisibilities]);

  return (
    <ControlledSelect
      name='visibility'
      label='Visibility'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};
