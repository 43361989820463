import {
  CAN_CHANGE_PROPOSAL_DEALS,
  CAN_EDIT_ADVERTISERS,
  CAN_EDIT_CAMPAIGNS,
  CAN_EDIT_GLOBAL_TARGETINGS,
  CAN_EDIT_MARGIN,
  CAN_EDIT_MODELSETS,
  CAN_EDIT_TARGETINGS,
  CAN_OVERRIDE_BUDGET_VISIBILITY,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_SET_LINEITEM_DATA_COLLECTION,
  CAN_VIEW_ADVERTISERS,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_CUSTOM_TARGETINGS,
  CAN_VIEW_DYNAMIC_PRICES,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_VIEW_FINALIZED_DEALS,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_VIEW_MODELSETS,
  CAN_VIEW_TARGETINGS,
  CAN_CHANGE_GAID,
  CAN_VIEW_GAID,
  CAN_VIEW_COST,
  CAN_VIEW_LOOKER_REPORT,
  CAN_CHANGE_LOOKER_REPORT,
  CAN_DOWNLOAD_GAID,
  CAN_VIEW_BASIC_STATISTICS,
  CAN_VIEW_ADVANCED_STATISTICS,
  CAN_VIEW_CAMPAIGN_RELATED_OBJECTS,
  CAN_DOWNLOAD_CAMPAIGN_REPORT,
} from 'consts/permissions';

export function normalizeUserData(userObject) {
  const {
    id,
    username,
    buyer,
    permissions,
    campaignMargin: { ...campaignMargin },
  } = userObject;
  return {
    id,
    username,
    buyer,
    isLoggedIn: true,
    campaignMargin: campaignMargin,
    [CAN_VIEW_CAMPAIGNS]: permissions.includes(CAN_VIEW_CAMPAIGNS),
    [CAN_EDIT_CAMPAIGNS]: permissions.includes(CAN_EDIT_CAMPAIGNS),
    [CAN_PREVIEW_CAMPAIGNS]: permissions.includes(CAN_PREVIEW_CAMPAIGNS),
    [CAN_VIEW_MODELSETS]: permissions.includes(CAN_VIEW_MODELSETS),
    [CAN_EDIT_MODELSETS]: permissions.includes(CAN_EDIT_MODELSETS),
    [CAN_VIEW_TARGETINGS]: permissions.includes(CAN_VIEW_TARGETINGS),
    [CAN_EDIT_TARGETINGS]: permissions.includes(CAN_EDIT_TARGETINGS),
    [CAN_VIEW_ADVERTISERS]: permissions.includes(CAN_VIEW_ADVERTISERS),
    [CAN_EDIT_ADVERTISERS]: permissions.includes(CAN_EDIT_ADVERTISERS),
    [CAN_EDIT_GLOBAL_TARGETINGS]: permissions.includes(
      CAN_EDIT_GLOBAL_TARGETINGS,
    ),
    [CAN_VIEW_DYNAMIC_PRICES]: permissions.includes(CAN_VIEW_DYNAMIC_PRICES),
    [CAN_VIEW_HIDDEN_FEATURES]: permissions.includes(CAN_VIEW_HIDDEN_FEATURES),
    [CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST]: permissions.includes(
      CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
    ),
    [CAN_SET_LINEITEM_DATA_COLLECTION]: permissions.includes(
      CAN_SET_LINEITEM_DATA_COLLECTION,
    ),
    [CAN_EDIT_MARGIN]: permissions.includes(CAN_EDIT_MARGIN),
    [CAN_VIEW_CUSTOM_TARGETINGS]: permissions.includes(
      CAN_VIEW_CUSTOM_TARGETINGS,
    ),
    [CAN_VIEW_EVERY_BUYER_OBJECTS]: permissions.includes(
      CAN_VIEW_EVERY_BUYER_OBJECTS,
    ),
    [CAN_VIEW_FINALIZED_DEALS]: permissions.includes(CAN_VIEW_FINALIZED_DEALS),
    [CAN_CHANGE_PROPOSAL_DEALS]: permissions.includes(
      CAN_CHANGE_PROPOSAL_DEALS,
    ),
    [CAN_OVERRIDE_BUDGET_VISIBILITY]: permissions.includes(
      CAN_OVERRIDE_BUDGET_VISIBILITY,
    ),
    [CAN_CHANGE_GAID]: permissions.includes(CAN_CHANGE_GAID),
    [CAN_VIEW_GAID]: permissions.includes(CAN_VIEW_GAID),
    [CAN_VIEW_COST]: permissions.includes(CAN_VIEW_COST),
    [CAN_VIEW_LOOKER_REPORT]: permissions.includes(CAN_VIEW_LOOKER_REPORT),
    [CAN_CHANGE_LOOKER_REPORT]: permissions.includes(CAN_CHANGE_LOOKER_REPORT),
    [CAN_DOWNLOAD_GAID]: permissions.includes(CAN_DOWNLOAD_GAID),
    [CAN_VIEW_BASIC_STATISTICS]: permissions.includes(
      CAN_VIEW_BASIC_STATISTICS,
    ),
    [CAN_VIEW_ADVANCED_STATISTICS]: permissions.includes(
      CAN_VIEW_ADVANCED_STATISTICS,
    ),
    [CAN_VIEW_CAMPAIGN_RELATED_OBJECTS]: permissions.includes(
      CAN_VIEW_CAMPAIGN_RELATED_OBJECTS,
    ),
    [CAN_DOWNLOAD_CAMPAIGN_REPORT]: permissions.includes(
      CAN_DOWNLOAD_CAMPAIGN_REPORT,
    ),
  };
}
