import yup from 'libraries/yup';

export const REDUCER_KEY = 'campaigns';

export const CAMPAIGN_SHAPE = yup.object().shape({
  id: yup.number().integer().stripForApiRequest(),
  name: yup.string().required(),
  startDate: yup.date(),
  endDate: yup.date(),
  iabCategory: yup.string().required(),
  advertiserDomain: yup.string().required(),
  restrictedCategory: yup.array(yup.number().integer()).required(),
  status: yup
    .object()
    .shape({
      isActive: yup.bool().required(),
      isLivepreviewActive: yup.bool().required(),
    })
    .stripForApiRequest(),
  bidder: yup
    .object()
    .shape({
      upToDate: yup.bool().required(),
      isActive: yup.bool().required(),
      isLivepreviewActive: yup.bool().required(),
    })
    .stripForApiRequest(),
  budgetPerLineitem: yup.bool().required(),
  lookerStudioReport: yup.string(),
});
