import React from 'react';
import { ControlledCheckboxBoolean } from 'components';

const LineitemGeneralMinimalVcrIsActive = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledCheckboxBoolean
    name='general.minimalVcr.isActive'
    label='Status'
    checkedLabel='Is active'
    uncheckedLabel='Is not active'
    w='100%'
    {...props}
  />
);

LineitemGeneralMinimalVcrIsActive.displayName =
  'Lineitem.general.minimalVcr.isActive';

export { LineitemGeneralMinimalVcrIsActive };
