import { endOfNextWeek, startOfToday } from 'consts/dates';
import { selectAdvertiserAsOptions } from 'store/advertisers';
import {
  selectApps,
  selectDomains,
  selectIabCategories,
  selectRestrictedCategories,
} from 'store/consts';
import * as Yup from 'yup';

export const READ_ONLY_FIELDS = {
  id: Yup.number().integer(),
  status: Yup.object().shape({
    isActive: Yup.bool().required().default(false),
  }),
  budget: Yup.object().shape({
    remainingBudget: Yup.number(),
    spent: Yup.number(),
  }),
  actionTracking: Yup.object().shape({
    pixel: Yup.string(),
  }),
};

export const EDITABLE_FIELDS = {
  name: Yup.string().required().default('').label('Name'),
  startDate: Yup.date().default(startOfToday).label('Start Date'),
  endDate: Yup.date().default(endOfNextWeek).label('End Date'),
  iabCategory: Yup.string()
    .required()
    .default('')
    .label('IAB Category')
    .meta({ optionsSelector: selectIabCategories }),
  advertiserDomain: Yup.string()
    .required()
    .default('')
    .matches(
      /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i,
      'Invalid domain (eg. example.com)',
    )
    .label('Advertiser Domain'),
  restrictedCategory: Yup.array(Yup.number().integer())
    .required()
    .default([])
    .label('Restricted Category')
    .meta({ optionsSelector: selectRestrictedCategories }),
  margin: Yup.object().shape({
    percentValue: Yup.number().integer().label('Campaign margin'),
  }),
  advertiser: Yup.number()
    .nullable(true)
    .label('Aadvertiser')
    .meta({ optionsSelector: selectAdvertiserAsOptions }),
  lookerStudioReport: Yup.string().label('Looker Studio Report'),
};

export const EDITABLE_BUDGET_FIELDS = {
  budgetPerLineitem: Yup.bool()
    .required()
    .default(false)
    .label('Budget Per Lineitem'),
  budget: Yup.object().shape({
    totalBudget: Yup.number()
      .label('Total Budget')
      .typeError('Field cannot be empty'),
  }),
};

export const HISTORY_FIELDS = {
  targeting: Yup.object().shape({
    appPlacementTargeting: Yup.object().shape({
      placementList: Yup.array(Yup.string())
        .label('App Placement Targeting')
        .meta({ optionsSelector: selectApps }),
    }),
    sitePlacementTargeting: Yup.object().shape({
      placementList: Yup.array()
        .of(Yup.string())
        .label('Site Placement Targeting')
        .meta({ optionsSelector: selectDomains }),
    }),
  }),
  active: Yup.bool().label('Campaign is active'),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const validationSchema = (isBudgetVisible: boolean | undefined): any => {
  return Yup.object().shape({
    ...READ_ONLY_FIELDS,
    ...EDITABLE_FIELDS,
    ...HISTORY_FIELDS,
    ...(isBudgetVisible ? EDITABLE_BUDGET_FIELDS : {}),
  });
};

export const campaignSchema = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
  ...HISTORY_FIELDS,
});

export type CampaignInterface = Yup.InferType<typeof campaignSchema>;
