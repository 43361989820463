import { Grid } from '@chakra-ui/react';
import { Form, PermissionsGate, ResetButton, SubmitButton } from 'components';
import { FormProps } from 'components/Form';
import { Title } from 'components/Meta';
import { FormSkeleton } from 'components/Skeletons';
import {
  CAN_EDIT_CAMPAIGNS,
  CAN_CHANGE_LOOKER_REPORT,
} from 'consts/permissions';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { withCurrentCampaignId } from 'hoc';
import { useAppSelector, useAppDispatch } from 'hooks';
import { Fieldset, FormLayout, FormLayoutProps } from 'layouts';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { create, selectCampaign, update, fetchById } from 'store/campaigns';
import { isMarginEnabled, selectDefaultCampaignMargin } from 'store/user';
import { campaignShowPage } from './Campaign.Links';
import { validationSchema } from './Campaign.schema';
import { CampaignId } from './Campaign.types';
import {
  CampaignAdvertiser,
  CampaignAdvertiserDomain,
  CampaignBudgetPerLineitem,
  CampaignEndDate,
  CampaignIabCategory,
  CampaignMargin,
  CampaignName,
  CampaignRestrictedCategory,
  CampaignStartDate,
  CampaignTotalBudget,
  CampaignLookerStudioReport,
} from './formFields';

interface CampaignFormProps extends FormProps, FormLayoutProps {
  redirectOnSuccess?: boolean;
  editMode?: boolean;
  budgetPerLineitem?: boolean;
}

const CampaignForm = ({
  heading,
  defaultValues,
  onSubmit,
  template,
  redirectOnSuccess,
  marginEnabled,
  editMode,
  budgetPerLineitem,
  ...props
}: CampaignFormProps): JSX.Element => {
  const history = useHistory();
  const budgetVisible = isBudgetVisible();

  function handleSubmit(campaign: any): void {
    if (campaign.id && redirectOnSuccess) {
      history.push(campaignShowPage(campaign.id));
    }
    if (typeof onSubmit === 'function') onSubmit(campaign);
  }

  const FORM_VALIDATION_SCHEMA = validationSchema(budgetVisible);

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={FORM_VALIDATION_SCHEMA}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      {...props}
    >
      <FormLayout template={template}>
        <FormLayout.Heading>{heading}</FormLayout.Heading>
        <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
          <FormLayout.Actions>
            <SubmitButton />
            <ResetButton />
          </FormLayout.Actions>
        </PermissionsGate>
        <FormLayout.Body>
          <Grid as={Fieldset} gap={6} templateColumns='repeat(12,1fr)'>
            <CampaignName gridColumn={{ base: 'span 12', md: 'span 6' }} />
            <CampaignStartDate gridColumn={{ base: 'span 6', md: 'span 3' }} />
            <CampaignEndDate gridColumn={{ base: 'span 6', md: 'span 3' }} />
            {budgetVisible && (
              <>
                <CampaignTotalBudget
                  disabled={budgetPerLineitem}
                  gridColumn={{ base: 'span 4', md: 'span 4' }}
                />
                <CampaignBudgetPerLineitem
                  isDisabled={editMode}
                  gridColumn={{ base: 'span 6', md: 'span 4' }}
                />
              </>
            )}
            <CampaignIabCategory
              gridColumn={{ base: 'span 8', lg: 'span 4' }}
            />
            <CampaignRestrictedCategory
              gridColumn={{ base: 'span 12', lg: 'span 4' }}
            />
            <CampaignAdvertiser gridColumn={{ base: 'span 6', md: 'span 4' }} />
            <CampaignAdvertiserDomain
              gridColumn={{ base: 'span 6', md: 'span 4' }}
            />
            {marginEnabled && (
              <CampaignMargin gridColumn={{ base: 'span 12', md: 'span 6' }} />
            )}
            <PermissionsGate requiredPermissions={[CAN_CHANGE_LOOKER_REPORT]}>
              <CampaignLookerStudioReport gridColumn={{ base: 'span 12' }} />
            </PermissionsGate>
          </Grid>
        </FormLayout.Body>
      </FormLayout>
    </Form>
  );
};

export const CreateCampaignForm = ({
  ...props
}: FormLayoutProps): JSX.Element => {
  const marginEnabled = useAppSelector(isMarginEnabled);
  const accountDefaults = {
    margin: {
      percentValue: useAppSelector(selectDefaultCampaignMargin),
    },
  };

  const DEFAULT_VALUES = validationSchema(isBudgetVisible()).getDefault();
  const defaultVals = { ...DEFAULT_VALUES, ...accountDefaults };

  return (
    <>
      <Title name={'Campaign Create'} />
      <CampaignForm
        heading={'Create Campaign'}
        messageOnSuccess='Campaign was successfully created'
        dispatchMethod={create}
        defaultValues={defaultVals}
        marginEnabled={marginEnabled}
        redirectOnSuccess={true}
        {...props}
      />
    </>
  );
};

export const EditCampaignForm = ({
  campaignId,
  ...props
}: FormLayoutProps & CampaignId): JSX.Element => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchById({ campaignId }));
  }, [campaignId, dispatch]);

  const campaignValues = useAppSelector(state =>
    selectCampaign(state, campaignId),
  );

  if (!campaignValues?.iabCategory) return <FormSkeleton />;
  const marginEnabled = campaignValues.margin.enabled;
  const budgetPerLineitem = campaignValues.budgetPerLineitem;

  return (
    <>
      <Title name={`Campaign Edit ${campaignValues.name}`} />
      <CampaignForm
        heading='Edit Campaign'
        messageOnSuccess='All changes was successfully saved'
        dispatchMethod={update}
        defaultValues={campaignValues}
        marginEnabled={marginEnabled}
        editMode={true}
        budgetPerLineitem={budgetPerLineitem}
        {...props}
      />
    </>
  );
};

export const EditCurrentCampaignForm = withCurrentCampaignId(EditCampaignForm);
