export const CAN_VIEW_CAMPAIGNS = 'view_campaign';
export const CAN_EDIT_CAMPAIGNS = 'change_campaign';
export const CAN_PREVIEW_CAMPAIGNS = 'preview_campaign';
export const CAN_VIEW_MODELSETS = 'view_modelset';
export const CAN_EDIT_MODELSETS = 'change_modelset';
export const CAN_VIEW_TARGETINGS = 'view_targetinglist';
export const CAN_EDIT_TARGETINGS = 'change_targetinglist';
export const CAN_VIEW_ADVERTISERS = 'view_advertiser';
export const CAN_EDIT_ADVERTISERS = 'change_advertiser';
export const CAN_EDIT_GLOBAL_TARGETINGS = 'change_global_targetinglist';
export const CAN_VIEW_DYNAMIC_PRICES = 'view_dynamicprice';
export const CAN_VIEW_HIDDEN_FEATURES = 'view_hidden_features';
export const CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST =
  'view_agencyname_oncampaignlist';
export const CAN_SET_LINEITEM_DATA_COLLECTION = 'set_lineitem_data_collection';
export const CAN_EDIT_MARGIN = 'change_margin';
export const CAN_VIEW_CUSTOM_TARGETINGS = 'view_custom_targetings';
export const CAN_VIEW_EVERY_BUYER_OBJECTS = 'view_every_buyer_objects';
export const CAN_VIEW_FINALIZED_DEALS = 'view_finalized_deals';
export const CAN_CHANGE_PROPOSAL_DEALS = 'change_proposal_deals';
export const CAN_OVERRIDE_BUDGET_VISIBILITY = 'override_budget_visibility';

export const CAN_VIEW_LOOKER_REPORT = 'view_looker_report';
export const CAN_CHANGE_LOOKER_REPORT = 'change_looker_report';

export const CAN_DOWNLOAD_GAID = 'download_gaid';
export const CAN_VIEW_GAID = 'view_gaid';
export const CAN_CHANGE_GAID = 'change_gaid';

export const CAN_VIEW_BASIC_STATISTICS = 'view_basic_statistics';
export const CAN_VIEW_ADVANCED_STATISTICS = 'view_advanced_statistics';
export const CAN_VIEW_COST = 'view_cost';

export const CAN_VIEW_CAMPAIGN_RELATED_OBJECTS =
  'view_campaign_related_objects';
export const CAN_DOWNLOAD_CAMPAIGN_REPORT = 'download_campaign_report';

export const ALL_PERMISSIONS = [
  CAN_VIEW_CAMPAIGNS,
  CAN_EDIT_CAMPAIGNS,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_VIEW_MODELSETS,
  CAN_EDIT_MODELSETS,
  CAN_VIEW_TARGETINGS,
  CAN_EDIT_TARGETINGS,
  CAN_VIEW_ADVERTISERS,
  CAN_EDIT_ADVERTISERS,
  CAN_EDIT_GLOBAL_TARGETINGS,
  CAN_VIEW_DYNAMIC_PRICES,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_SET_LINEITEM_DATA_COLLECTION,
  CAN_EDIT_MARGIN,
  CAN_VIEW_CUSTOM_TARGETINGS,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_VIEW_FINALIZED_DEALS,
  CAN_CHANGE_PROPOSAL_DEALS,
  CAN_OVERRIDE_BUDGET_VISIBILITY,
  CAN_VIEW_LOOKER_REPORT,
  CAN_CHANGE_LOOKER_REPORT,
  CAN_DOWNLOAD_GAID,
  CAN_VIEW_GAID,
  CAN_CHANGE_GAID,
  CAN_VIEW_BASIC_STATISTICS,
  CAN_VIEW_ADVANCED_STATISTICS,
  CAN_VIEW_COST,
  CAN_VIEW_CAMPAIGN_RELATED_OBJECTS,
  CAN_DOWNLOAD_CAMPAIGN_REPORT,
];
