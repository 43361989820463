import React from 'react';
import { ButtonProps, Button } from '@chakra-ui/react';
import { ModalForm, PermissionsGate } from 'components';
import { CAN_CHANGE_GAID, CAN_VIEW_GAID } from 'consts/permissions';
import { GaidId } from './Gaid.types';
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { userHasRequiredPermissions } from 'hooks';
import { CreateGaidForCurrentCampaignForm, EditGaidForm } from './Gaid.Forms';

export const CreateGaidModalForm = ({ ...props }: ButtonProps): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_CHANGE_GAID]}>
    <ModalForm
      trigger={
        <Button {...props} variant='solid' leftIcon={<FiPlus />}>
          Create Gaid List
        </Button>
      }
      form={<CreateGaidForCurrentCampaignForm />}
    />
  </PermissionsGate>
);

export const EditGaidModalForm = ({
  gaidId,
  ...props
}: GaidId & ButtonProps): JSX.Element => {
  const canEdit = userHasRequiredPermissions([CAN_CHANGE_GAID]);
  const canPreview = userHasRequiredPermissions([CAN_VIEW_GAID]);
  const icon = canEdit ? <FiEdit /> : <FiEye />;
  const text = canEdit ? 'Edit' : 'Preview';

  if (canEdit || canPreview) {
    return (
      <ModalForm
        trigger={
          <Button {...props} variant='solid' leftIcon={icon}>
            {text}
          </Button>
        }
        form={<EditGaidForm gaidId={gaidId} />}
      />
    );
  }
  return <></>;
};
